<template>
	<div class="view">
		<div class="main">
			<!-- <div class="video-wrap">
				<video width="100%" height="100%" id="player1" controls autoplay muted playsinline>
					<source :src="videoUrl" type="video/mp4" />
				</video>
			</div> -->
			<b-jumbotron fluid container-fluid class="jumbotron-carousel">
				<b-carousel indicators :data-slogan="slogan">
					<b-carousel-slide v-for="i in Array(1, 2, 3, 4)" :key="i" :img-src="require(`@/assets/landing-page/carousel-${i}.png`)" />
					<div class="carousel-slogan-wrap">
						<div class="carousel-slogan slogan">
							놀공스쿨이 선생님과 다음 세대를 위한 미래학교를 향한 여정을 함께 합니다.
						</div>
						<div class="carousel-slogan slogan hidden">공간 리터러시 수업 ‘건축놀이사무소’로 학교공간을 혁신하고<br />디지털 통일 수업 ‘월페커즈’로 미래교육 문화를 만들어 나갑니다.</div>

						<!-- <div class="carousel-slogan slogan">
              교육출판전문기업 미래엔과 교육콘텐츠기업 놀공이<br />선생님들과 다음 세대를 위한 미래학교 프로젝트의 여정을 함께 합니다.
            </div> -->
						<!-- <div class="carousel-slogan slogan hidden">
              공간 리터러시 수업 ‘건축놀이사무소’로 학교공간을 혁신하고<br />디지털 통일 수업 ‘월페커즈’로 미래교육 문화를 만들어 나갑니다.
            </div> -->
					</div>
				</b-carousel>
			</b-jumbotron>
			<b-container class="bv-example-row">
				<header>
					<img :src="require('@/assets/logo.svg')" alt="놀공스쿨 로고" />
					<span>클릭하면 프로그램 페이지로 이동합니다.</span>
					<h2>온라인에서 바로 실행할 수 있는 디지털 콘텐츠</h2>
				</header>
				<!-- project1 -->
				<b-row cols="1" cols-sm="1" cols-md="3" cols-lg="3">
					<a href="/project1" title="월페커즈">
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project1.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">월페커즈</b-button>
							</b-card></b-col
						></a
					>
					<!-- project2 -->
					<a href="https://digi-citizen.nolgong.com" target="_blank" title="디지털 시민교육">
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project2.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">디지털 시민교육</b-button>
							</b-card></b-col
						></a
					>
					<!-- project3 -->
					<a href="https://eco-citizen.nolgong.com" target="_blank" title="디지털 환경 교육">
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project3.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">디지털 환경 교육</b-button>
							</b-card></b-col
						></a
					>
				</b-row>
			</b-container>
			<b-container class="bv-example-row">
				<header>
					<h2>방문교육/키트를 신청하고 이용하는 프로그램</h2>
				</header>
				<!-- project4 -->
				<b-row cols="1" cols-sm="1" cols-md="3" cols-lg="3">
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSde1kNnOyPeiWMB44JEgJI3ghN-JK5wJhq1zv8rYkhqs24JQA/viewform
"
						target="_blank"
						title="찾아가는 놀공! 방문교육 문의 | 놀공스쿨 바로가기"
					>
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project4.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">독도</b-button>
								<!-- <b-button href="/project4" variant="primary">4.독도</b-button> -->
							</b-card></b-col
						></a
					>
					<!-- project5 -->
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSde1kNnOyPeiWMB44JEgJI3ghN-JK5wJhq1zv8rYkhqs24JQA/viewform
"
						target="_blank"
						title="찾아가는 놀공! 방문교육 문의 | 놀공스쿨 바로가기"
					>
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project5.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">커리어 마운틴</b-button>
								<!-- <b-button href="/project5" variant="primary">5.커리어 마운틴</b-button> -->
							</b-card></b-col
						></a
					>
					<!-- project6 -->
					<a href="/project6" title="건축놀이사무소">
						<b-col class="card-wrap">
							<b-card class="mb-2">
								<figure class="circle-box-wrap">
									<b-card-img :src="require('@/assets/landing-page/project6.png')" alt="Image"></b-card-img>
								</figure>
								<b-button variant="primary">건축놀이사무소</b-button>
							</b-card></b-col
						></a
					>
				</b-row>
			</b-container>
			<!-- TODO 하단 검은색 레이어 추가 -->
			<b-tabs justified v-model="tabIndex" style="display: none;">
				<b-tab>
					월페커즈
					<template v-slot:title>
						<img src="@/assets/logo-02.png" alt="월페커즈인스쿨" />
					</template>
					<b-jumbotron fluid container-fluid class="even">
						<template v-slot:header>통일교육은 '월페커즈 인스쿨'로</template>
						<template v-slot:lead>
							한국과 독일의 분단과 통일의 역사를 게임으로 풀어낸 디지털 통일 교육 프로그램 월페커즈,
							<br class="desktop-only" />학생들이 기자가 되어 육하원칙에 따라 양국에서 일어난 사건기사를 완성해 나가며 <br class="desktop-only" />한반도의 통일 문제를 세계사적 관점으로 이해하게 됩니다.
						</template>
						<!-- <b-row><br /></b-row> -->
						<iframe width="600" height="335" src="https://player.vimeo.com/video/669635807?h=02bb5491fb" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-jumbotron>
					<b-jumbotron fluid container-fluid class="odd">
						<template v-slot:header>월페커즈 수업의 특장점</template>
						<template v-slot:lead>월페커즈로 수업하면 이런 장점이 있습니다.</template>
						<!-- 데스크탑 뷰 (세로 카드) -->
						<b-card-group deck class="transparent">
							<b-card :img-src="require('@/assets/landing-page/icon1.png')" title="">게임으로 쉽게, 재미있게 접근하는 <br class="desktop-only" /><span class="bold">디지털 통일교육 콘텐츠</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon2.png')" title="">학생이 주인공(player)이 되어 몰입도를 높여주는 <span class="bold">체험형 교육프로그램</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon3.png')" title="">교실수업 및 원격수업이 모두 가능한 <br class="desktop-only" /><span class="bold">블렌디드 교육방식</span></b-card>
						</b-card-group>
						<!-- 모바일 뷰 (가로 카드) -->
						<b-card-group columns class="transparent">
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon1.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 게임으로 쉽게, 재미있게 접근하는 <span class="bold">디지털 통일교육 콘텐츠</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon2.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 학생이 주인공(player)이 되어 몰입해서 활동하게 하는 <span class="bold">체험형 교육프로그램</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon3.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 교실수업 및 원격수업이 모두 가능한 <span class="bold">O2O 교육방식</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
						</b-card-group>
					</b-jumbotron>

					<b-jumbotron fluid container-fluid class="even wide halves">
						<template v-slot:header>월페커즈 수업후기</template>
						<template v-slot:lead>선생님들이 월페커즈로 통일교육을 하는 이유 </template>
						<b-card-group deck>
							<b-card title="월페커즈 수업결과 활용">
								<b-card-text>
									월페커즈 수업이 끝나면 결과물로 기사가 발행되는데, 이 기사 자체가 다음 수업 콘텐츠였습니다. 아이들이 발행한 기사를 가지고 다음 수업을 진행했는데, 복습 이상의 가치 있는 수업이었습니다. 수업결과를 가지고 다시 수업한다는 게 아이들에게나 교사에게나 신선한 경험이었고
									의미있었습니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">일산 **초등학교</span><br />최OO 선생님</template>
							</b-card>
							<b-card title="소통, 성취감">
								<b-card-text> 모바일기기로 통일수업을 한다는 것을 흥미롭게 느끼구요, 팀을 이루어 기사를 완성하는 미션을 수행하면서 소통을 많이 하게 돼요. 잘 안 풀릴 때는 전략을 다시 짜고 문제를 해결해 나가는 과정에서 아이들의 성취감, 만족도가 높은 수업이었습니다.</b-card-text>
								<template v-slot:footer><span class="bold">서울 **초등학교</span><br />김OO 선생님</template>
							</b-card>
							<b-card title="몰입">
								<b-card-text>
									학생들에게는 어려운 분단과 통일의 역사를 게임으로 쉽게 배울 수 있습니다. 열심히 정보를 모으고 기사를 작성하면서 자신도 모르게 벌써 30분이 지나가 버리는 경험을 하는 학생들을 보면 행복합니다. 통일수업에서 학생이 몰입하는 모습을 경험하고 싶은 선생님들께 적극적으로
									추천합니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">나주 **초등학교</span><br />박OO 선생님</template>
							</b-card>
							<b-card title="월페커즈 앳홈 후기">
								<b-card-text> 줌(ZOOM)으로 화상수업을 하면서 아이들이 집에서 월페커즈 통일게임에 참여했고 발행한 기사를 숙제로 제출했습니다. 교실수업 뿐만 아니라 원격수업을 진행할 수 있는 것은 큰 장점이라고 생각합니다.</b-card-text>
								<template v-slot:footer><span class="bold">남양주 **초등학교</span><br />김OO 선생님</template>
							</b-card>
						</b-card-group>
					</b-jumbotron>
				</b-tab>
				<b-tab active>
					<!-- 영릉 시간 탐험대 -->
					<template v-slot:title>
						<img src="@/assets/logo-09.png" alt="영릉시간탐험대" />
					</template>
					<b-jumbotron fluid container-fluid class="even">
						<template v-slot:header
							>세종대왕의 기록을 찾아라! <br />
							'영릉 시간 탐험대'</template
						>
						<template v-slot:lead>
							역사상 가장 위대한 왕, 세종대왕이 잠든 여주 영릉이 메타버스로 거듭났습니다.
							<br class="desktop-only" />'영릉 시간 탐험대'는 집현전의 학자가 되어 메타버스 속 영릉을 누비며 <br class="desktop-only" />세종대왕의 기록을 수집하는 프로그램으로 <br class="desktop-only" />국어, 사회, 역사 등 다양한 교과를 게임과 메타버스를 통해 체험할 수 있습니다.
						</template>
						<iframe width="600" height="330" src="https://player.vimeo.com/video/711092367?h=d314fcc0fb" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-jumbotron>
					<b-jumbotron fluid container-fluid class="odd">
						<template v-slot:header>영릉 시간 탐험대 수업의 특장점</template>
						<template v-slot:lead>영릉 시간 탐험대로 수업하면 이런 장점이 있습니다. </template>
						<!-- 데스크탑 뷰 (세로 카드) -->
						<b-card-group deck class="transparent">
							<b-card :img-src="require('@/assets/landing-page/icon1.png')" title="">게임으로 쉽게, 재미있게 접근하는 <br class="desktop-only" /><span class="bold">디지털 역사문화 콘텐츠</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon2.png')" title="">학생이 주인공(player)이 되어 몰입도를 높여주는 <span class="bold">체험형 교육프로그램</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon3.png')" title="">교실수업 및 원격수업이 모두 가능한 <br class="desktop-only" /><span class="bold">블렌디드 교육방식</span></b-card>
						</b-card-group>
						<!-- 모바일 뷰 (가로 카드) -->
						<b-card-group columns class="transparent">
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon1.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 게임으로 쉽게, 재미있게 접근하는 <span class="bold">디지털 역사문화 콘텐츠</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon2.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 학생이 주인공(player)이 되어 몰입해서 활동하게 하는 <span class="bold">체험형 교육프로그램</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon3.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 교실수업 및 원격수업이 모두 가능한 <span class="bold">O2O 교육방식</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
						</b-card-group>
					</b-jumbotron>

					<b-jumbotron fluid container-fluid class="even wide thirds">
						<template v-slot:header>영릉 시간 탐험대 수업 후기</template>
						<template v-slot:lead>선생님들이 영릉 시간 탐험대 수업을 하는 이유</template>
						<b-card-group deck>
							<b-card title="">
								<b-card-text> 교실에만 앉아있는 것을 힘들어하는 학생도 메타버스 공간에 들어가니 너무나 즐겁게 수업에 몰입하기 시작했어요. 단순히 정보를 발견하는 것 뿐만이 아니라 퀴즈를 풀며 한번 더 복습하니 더욱 재미있게 배움이 일어나는 것 같았습니다!</b-card-text>
								<template v-slot:footer><span class="bold">대전 **초등학교</span><br />김00 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 대부분의 학생들이 지루함을 느끼는 역사시간에 영릉 시간탐험대 수업을 활용하니 학생들이 오히려 이 시간을 기다리더라구요! 수업에 열정이 없던 학생도 최고 관직인 영전사를 달기 위해 열심히 메타버스 공간을 돌아다니는 모습이 아주 인상적이었습니다.</b-card-text>
								<template v-slot:footer><span class="bold">충남 **초등학교</span><br />박00 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 견학을 자주 하기 힘든 학교에게 안성맞춤인 콘텐츠 인 것 같아요. 영릉의 모습을 구현한 공간을 돌아다니는 것 만으로도 즐거워했지만 중간중간 실제 공간의 사진이 들어있어 더욱 현장감이 느껴졌던 것 같아요.</b-card-text>
								<template v-slot:footer><span class="bold">경북 **초등학교</span><br />이00 선생님</template>
							</b-card>
						</b-card-group>
					</b-jumbotron>
				</b-tab>
				<b-tab>
					<!-- 건놀사 -->
					<template v-slot:title>
						<img src="@/assets/logo-01.png" alt="건축놀이사무소" />
					</template>
					<b-jumbotron fluid container-fluid class="even">
						<template v-slot:header
							>학교공간혁신을 위한 공간감수성 수업,<br />
							'건축놀이사무소'</template
						>
						<template v-slot:lead>
							동화 속 주인공들이 건축물을 의뢰하면, 학생들이 건축전문가가 되어
							<br class="desktop-only" />의뢰를 해결하는 팀프로젝트형 프로그램 건축놀이사무소, <br class="desktop-only" />다양한 교과와 연계 가능한 건축놀이를 통해 학생들은 <br class="desktop-only" />공간을 읽고 활용할 수 있는 공간감수성을 높이게 됩니다.
						</template>
						<iframe width="600" height="330" src="https://player.vimeo.com/video/667028916?h=5ba1f2632d" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</b-jumbotron>
					<b-jumbotron fluid container-fluid class="odd">
						<template v-slot:header>건축놀이사무소의 특장점</template>
						<template v-slot:lead>건축놀이사무소로 수업하면 이런 장점이 있습니다.</template>
						<!-- 데스크탑 뷰 -->
						<b-card-group deck class="transparent">
							<b-card :img-src="require('@/assets/landing-page/icon7.png')" title="">학교 공간을 다시 보는 <br class="desktop-only" /><span class="bold">공간중심 사고력 향상</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon8.png')" title="">실제 공간의 변화로 이어지는 <span class="bold">혁신수업</span></b-card>
							<b-card :img-src="require('@/assets/landing-page/icon9.png')" title="">역할 중심의 놀이로 이뤄지는 <br class="desktop-only" /><span class="bold">프로젝트중심 수업</span></b-card>
						</b-card-group>
						<!-- 모바일 뷰 -->
						<b-card-group columns class="transparent">
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon7.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 학교 공간을 다시 보는 <br class="desktop-only" /><span class="bold">공간중심 사고력 향상</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon8.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 실제 공간의 변화로 이어지는 <span class="bold">혁신수업</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
							<b-card no-body>
								<b-row no-gutters align-v="center">
									<b-col cols="4" xs="4">
										<b-card-img :src="require('@/assets/landing-page/icon9.png')"></b-card-img>
									</b-col>
									<b-col cols="8" xs="8">
										<b-card-body>
											<b-card-text> 역할 중심의 놀이로 이뤄지는 <br class="desktop-only" /><span class="bold">프로젝트중심 수업</span> </b-card-text>
										</b-card-body>
									</b-col>
								</b-row>
							</b-card>
						</b-card-group>
					</b-jumbotron>

					<b-jumbotron fluid container-fluid class="even wide thirds">
						<template v-slot:header>건축놀이사무소 수업후기</template>
						<template v-slot:lead>선생님들이 건축놀이사무소로 공간교육을 하는 이유 </template>
						<b-card-group deck>
							<b-card title="">
								<b-card-text>
									학교 공간을 탐색해 보고, 건축놀이를 만드는 과정에 대해 고민하는 시간을 갖고 나니, 학생들이 스스로 공간을 찾고 건축놀이를 만들어 나가기 시작했어요. 교사에게 의존하지 않고, 서로에게 퍼실리테이터가 되어 건축물을 완성해 내는 것을 보고 깜짝 놀랐습니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">부산 **초등학교</span><br />이OO 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text> 아이들은 혼자 공부할 때보다 서로 소통하고 협동할 때 창의력을 더 잘 발휘하는 것 같아요. 건축주의 재미있는 의뢰를 받고 공간에 대한 상상력을 총동원해서 문제를 해결해가는 과정에서 그 어떤 수업보다 활발한 의견교환과 웃음이 있었던 수업이었습니다.</b-card-text>
								<template v-slot:footer><span class="bold">구리 **초등학교</span><br />김OO 선생님</template>
							</b-card>
							<b-card title="">
								<b-card-text>
									공간은 그 안에서 활동하는 인간의 사고과정에 큰 영향을 미칩니다. 아이들이 가장 많은 시간을 보내는 학교에 대한 긍정적인 경험을 하게 만드는 것도 교육입니다.<br />
									건축놀이사무소는 늘상 똑같아 보이는 학교라는 공간을 새롭게 바라보고, 공간과 공간의 요소들을 활용하여 즐거운 건축놀이를 하게 해 줍니다.</b-card-text
								>
								<template v-slot:footer><span class="bold">춘천 **초등학교</span><br />한OO 선생님</template>
							</b-card>
						</b-card-group>
					</b-jumbotron>
				</b-tab>
			</b-tabs>

			<b-jumbotron fluid container-fluid class="kakaotalk">
				<template v-slot:lead>
					<a href="https://pf.kakao.com/_sFfTxb" target="_blank">
						카카오톡 @놀공 채널로 1:1 문의하세요.
						<img class="kakao-logo" src="@/assets/logo-08.png" />
					</a>
				</template>
			</b-jumbotron>

			<!-- <div class="workshop-schedule">
        <div class="icon-close"><b-icon icon="x" v-on:click="hideWorkshopSchedule"></b-icon></div>
        <a href="https://www.notion.so/86fd5b8b546a4751b1dffb363e414a21" target="_blank">
          <img src="@/assets/landing-page/schedule.png">
        </a>
      </div> -->
			<!-- popup -->
			<!-- <div v-if="login" class="pop-up login one border-light border shadow">
				<div v-show="showAlert1">
					<b-row class="text-left m-0" align-h="start">
						<img src="@/assets/popup_2205_1.png" :alt="alertImgAlt" style="width: 100%; height: auto;" />
					</b-row>
					<b-row class="popup-btn-wrap text-center m-0" align-h="center" align-v="center">
						<b-button data-id="pop1" squared class="popup-btn" v-on:click="todayMakeAcknowledge(noticeId[0])">1일간 보지 않기</b-button>
						<b-button squared id="anchor-close" class="popup-btn border-0" aria-label="닫기" v-on:click="closePopup(noticeId[0])">닫기</b-button>
					</b-row>
				</div>
			</div> -->
			<!-- <div v-else class="pop-up one border-light border shadow">
				<div v-show="showAlert1">
					<b-row class="text-left m-0" align-h="start">
						<img src="@/assets/popup_2205_3.png" :alt="alertImgAlt" style="width: 100%; height: auto;" />
					</b-row>
					<b-row class="popup-btn-wrap text-center m-0" align-h="center" align-v="center">
						<b-button data-id="pop1" squared class="popup-btn" v-on:click="todayMakeAcknowledge(noticeId[0])">1일간 보지 않기</b-button>
						<b-button squared id="anchor-close" class="popup-btn border-0" aria-label="닫기" v-on:click="closePopup(noticeId[0])">닫기</b-button>
					</b-row>
				</div>
			</div> -->
			<!-- <div v-show="showAlert2" class="pop-up two border-light border shadow">
				<b-row class="text-left m-0" align-h="start">
					<img src="@/assets/popup_2205_2.png" :alt="alertImgAlt" style="width: 100%; height: auto;" />
				</b-row>
				<b-row class="popup-btn-wrap text-center m-0" align-h="center" align-v="center">
					<b-button data-id="pop2" squared class="popup-btn" v-on:click="todayMakeAcknowledge(noticeId[1])">1일간 보지 않기</b-button>
					<b-button squared id="anchor-close" class="popup-btn border-0" aria-label="닫기" v-on:click="closePopup(noticeId[1])">닫기</b-button>
				</b-row>
			</div> -->

			<Footer></Footer>

			<!--       <AlertToast
        :imgSrc="require('@/assets/alert-2021-06_796.png')"
        :imgAlt="alertImgAlt"
        :notice-id="'202106'"
      /> -->
		</div>
	</div>
</template>

<script>
// 로그인 페이지
import Footer from '@/components/Footer';

// import AlertToast from "@/components/GenericAlertToast"

export default {
	name: 'Landing',
	data() {
		return {
			// video
			videoUrl:
				'https://s3.ap-northeast-2.amazonaws.com/miraeclass.com/%E1%84%8B%E1%85%AF%E1%86%AF%E1%84%91%E1%85%A6%E1%84%8F%E1%85%A5%E1%84%8C%E1%85%B3_%E1%84%92%E1%85%A9%E1%86%BC%E1%84%87%E1%85%A9%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC_%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC.mp4',
			scrollY: 0,
			tabIndex: 0,
			timer: null,
			trackedURLs: {
				logo: ['http://bit.ly/2LHTRtG', 'http://bit.ly/3sePkzO'],
				button: ['http://bit.ly/2MHeZ3R', 'http://bit.ly/38vf1Es'],
			},
			doNotShowAgain: false,
			login: false,
			popup: true,
			noticeId: ['popup1', 'popup2'],
			// noticeId: "20220501",
			// noticeId: "20220502",
			showAlert1: false,
			showAlert2: false,
			alertImgAlt: `놀공스쿨에서 알려드립니다!
월페커즈 선생님!
월페커즈 인스쿨 앱 다운로드 받지 마세요.
간편하게 원클릭으로 웹사이트에서 플레이하세요.

주의: 기존에 앱스토어에서 다운받았던
월페커즈 인스쿨 앱은 더 이상 사용할 수 없습니다.

1. 교사용 웹서비스 http://nolgongschool.com/ 로그인
2. 수업관리>수업 새로 등록하기 클릭
3. 과목에서 ‘월페커즈 인스쿨’(교실수업용) 또는 ‘월페커즈 앳홈‘(원격수업용) 중 선택
4. 날짜, 시간, 게임길이 등 수업정보 입력 후 등록
5. 생성한 수업을 클릭면, 하단에 보이는 게임 참가링크,
   프레스코드 등 수업정보를 복사해 학생에게 전달
6. 학생은 선생님께 전달받은 게임 참가링크를 통해
   로그인, 프레스코드를 입력해 게임을 진행

[수업팁] 프레스코드는 학생들이 동시에 입력하도록 안내해 주세요. 그래야 게임을 동시에 시작해서 동시에 끝낼 수 있답니다.
원격수업에는 <월페커즈 앳홈>
교실수업이든 원격수업이든, 장소에 구애받지 않고
월페커즈 즐거운 통일교육 하세요.`,
		};
	},
	mounted() {
		// 핸들러 등록하기
		this.sloganInterval = setInterval(this.swapSlogan, 10000);
		setTimeout(() => document.querySelector('.workshop-schedule').classList.add('visible'), 2000);

		// if (!this.$store.getters.popupAcknowledged(this.noticeId[0])) {
		// 	this.showAlert1 = true;
		// }
		// if (!this.$store.getters.popupAcknowledged(this.noticeId[1])) {
		// 	this.showAlert2 = true;
		// }

		// console.log(this.login, this.$store.state.token, this.$store.state.token.length);
		if (this.$store.state.token.length) {
			this.login = true;
			// console.log(this.login, this.$store.state.token);
		}
	},
	beforeDestroy: function() {
		// 핸들러 제거하기
		clearInterval(this.slogalInterval);
	},
	computed: {
		slogan() {
			return '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다.';
			// '공간 리터러시 수업 ‘건축놀이사무소’로 학교공간을 혁신하고<br>디지털 통일 수업 ‘월페커즈’로 미래교육 문화를 만들어 나갑니다.'
		},
	},
	methods: {
		showAlert() {
			alert('준비 중입니다. 곧 만나보실 수 있습니다!');
		},
		swapSlogan: function() {
			[...document.getElementsByClassName('carousel-slogan')].forEach((e) => {
				e.classList.toggle('hidden');
			});
		},
		handleScroll: function() {
			if (this.timer === null) {
				this.timer = setTimeout(
					function() {
						this.scrollY = window.scrollY;
						clearTimeout(this.timer);
						this.timer = null;
					}.bind(this),
					200
				);
			}
		},
		hideWorkshopSchedule: function() {
			document.querySelector('.workshop-schedule').style.display = 'none';
		},

		closePopup: function(id) {
			// console.log(this.doNotShowAgain);

			if (this.noticeId[0] === id) {
				this.showAlert1 = false;
			} else if (this.noticeId[1] === id) {
				this.showAlert2 = false;
			}
		},

		todayMakeAcknowledge: function(id) {
			// this.noticeId = id;
			// this.noticeId = e.currentTaget.dataset.id;
			// console.log(this.noticeId);

			if (this.noticeId[0] === id) {
				this.$store.dispatch('MAKE_ACKNOWLEDGE_POPUP', id);
				this.showAlert1 = false;
			} else if (this.noticeId[1] === id) {
				this.$store.dispatch('MAKE_ACKNOWLEDGE_POPUP', id);
				this.showAlert2 = false;
			}

			// if (!this.$store.getters.popupAcknowledged(this.noticeId)) {
			// 	this.$store.dispatch("MAKE_ACKNOWLEDGE_POPUP", this.noticeId);
			// 	this.popup = false;
			// 	console.log(this.noticeId);
			// }
		},
	},
	components: {
		Footer,
		// AlertToast
	},
	metaInfo: {
		// 페이지 제목 설정
		title: 'NOLGONG SCHOOL',
		titleTemplate: null,
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://nolgongschool.com/',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL" 문구',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: 'NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘NOLGONG SCHOOL’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구',
				vmid: 'twitter:image:alt',
			},
		],
	},
};
</script>

<style scoped>
.container {
	max-width: 1010px;
	margin-bottom: 0;
	border: none;
	box-shadow: none;
	background-color: #fff;
	border-radius: 0;
}

.container header {
	padding: 69px 0 60px;
	color: #474747;
}

.container header > img {
	display: block;
	width: 335px;
	margin: 20px auto;
	text-align: center;
}

.container header span {
	display: block;
	padding: 30px 0 20px;
	font-size: 14px;
}

.container header h2 {
	font-size: 36px;
}
.mobile-only {
	display: none;
}
.view {
	/*min-width: 540px;*/
}
.main {
	padding-top: calc(1rem + 40px);
	padding-left: 0;
	padding-bottom: 0;
	padding-right: 0;
	background-color: #fff;
	word-break: keep-all;
}
h1 {
	margin-bottom: 36px;
	font-weight: 800;
	font-size: 54px;
}
.lead {
	margin-bottom: 48px;
}
a {
	color: #474747;
}

.btn-primary {
	min-width: 220px;
	padding: 12px 30px;
	font-size: 24px;
	color: #fff;
	background-color: #ff6936;
	border-radius: 30px;
	border: 3px solid #fff;
}
/* tabs */
.main >>> .nav-tabs img {
	width: 100%;
	max-width: 240px;
}
.main >>> .tabs .nav-tabs {
	padding-top: 2rem;
	background-color: #e3ffde;
	border-bottom: none;
	justify-content: center;
}
.main >>> .tabs .nav-item {
	max-width: 342px;
	background-color: #e3ffde;
}
.main >>> .tabs .nav-link {
	padding-top: 1.5em;
	height: 100%;
	background-color: #/ wallpeckers-assets/;
	border-top-left-radius: 2em;
	border-top-right-radius: 2em;
}
.main >>> .tabs .nav-item:first-of-type {
	z-index: 3;
}
.main >>> .tabs .nav-item:nth-of-type(2) {
	z-index: 2;
}
.main >>> .tabs .nav-item:last-of-type {
	z-index: 1;
}
.main >>> .nav-tabs .nav-link:focus,
.main >>> .nav-tabs .nav-link:hover {
	background: rgba(255, 255, 255, 0.5);
	border-bottom: none;
}
.main >>> .tabs .nav-link.active {
	background-color: #fff;
	border-color: #f6f6f6;
	border-bottom: none;
	box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
}
.jumbotron-carousel >>> .carousel-inner {
	max-height: 800px;
}

.carousel-slogan-wrap {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6) 100%);
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
.carousel-slogan {
	padding-bottom: 6rem;
	font-weight: bold;
	font-size: 1.75rem;
	color: white;
	text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
	transition: opacity 1s ease-in-out;
	opacity: 1;
}
.carousel-slogan.hidden {
	opacity: 0;
	position: fixed;
}
.carousel >>> .carousel-indicators li {
	width: 10px;
	height: 10px;
	border-radius: 10px;
	border: none;
	margin: 1.5rem 0.5rem;
	opacity: 0.3;
	transition: all 0.25s;
}
.carousel >>> .carousel-indicators li.active {
	transform: scale(1.2);
	opacity: 1;
}

.jumbotron {
	margin-bottom: 0;
}
.jumbotron .container-fluid {
	max-width: 1024px;
}
.jumbotron.wide .container-fluid {
	max-width: 100%;
}
.jumbotron.odd {
	background: #e3ffde;
	min-height: 640px;
}
.jumbotron.even {
	background: #fff;
	min-height: 640px;
}
.jumbotron-carousel {
	/*width: 100%;*/
	padding: 0;
}
.jumbotron-carousel .container-fluid {
	max-width: 100%;
	padding: 0;
}
.jumbotron.splash {
	display: flex;
	align-items: center;
	min-height: 400px !important;
}
.jumbotron.splash .lead {
	font-weight: 600;
	font-size: 1.5em;
	margin-bottom: 120px;
	white-space: pre-wrap;
}
.jumbotron.kakaotalk {
	margin-top: 200px;
	background: #f6e24b;
}
.jumbotron.kakaotalk .lead {
	font-size: 36px;
	font-weight: bold;
	margin-bottom: auto;
}
.kakao-logo {
	max-height: 50px;
	margin-left: 16px;
}

.card-wrap {
	display: flex;
	justify-content: center;
}

.card-wrap .card {
	width: 100%;
	height: 100%;
	border: none;
	box-shadow: none;
	background-color: transparent;
}

.card-wrap .card-body {
	padding: 0;
	text-align: center;
	cursor: pointer;
}
.card-title {
	font-weight: bold;
}
.card {
	word-break: break-all;
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}

.circle-box-wrap {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;
	padding-top: 100%; /* 가로 세로 1:1 비율 유지 */
	/* max-width: 282px; */
	max-height: 282px;
	padding-left: 34px;
	padding-right: 34px;
	border-radius: 50%;
	border: 1px solid #d9d9d9;
	background: #fff;
}

.circle-box-wrap img {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 34px;
	transform: translate(-50%, -50%);
}

.card,
.card-footer {
	border-radius: 10px;
}
.card-footer {
	background-color: white;
	border-top: none;
}
.jumbotron.wide .card-title {
	margin-top: 0.5em;
}
.jumbotron.wide .card-text::before,
.jumbotron.wide .card-text::after {
	/*font-size: 125%;*/
	font-style: oblique;
}
.jumbotron.wide .card-text::before {
	content: '“';
}
.jumbotron.wide .card-text::after {
	content: '”';
}
.transparent .card {
	background: transparent;
	border: none;
	box-shadow: none;
}
.card-columns.transparent {
	display: none;
}
.card-text {
	text-align: justify;
	padding-top: 1em;
	padding-bottom: 1em;
}
.card-img {
	padding: 1em;
}

.card-img-top {
	padding: 10px 34px;
	max-height: 170px;
	object-fit: contain;
}
.call-to-action {
	margin-top: 1em;
	font-size: 2em;
	padding: 0.25rem 0.75rem 0.5rem;
}
.call-to-action a {
	vertical-align: text-bottom;
	color: #f8f8f8;
}

.workshop-schedule {
	transition: all 1.5s;
	opacity: 0;
	z-index: 10;
	position: fixed;
	top: 80px;
	right: -200px;
	text-align: right;
	font-size: 1.5em;
}
.workshop-schedule.visible {
	opacity: 1;
	right: 1rem;
}
.workshop-schedule img {
	max-height: 144px;
	max-width: 144px;
}
.icon-close {
	opacity: 0;
	transition: opacity 3s cubic-bezier(1, -0.15, 0.5, 1);
	color: gray;
	line-height: 1em;
	margin-right: -6px;
	margin-bottom: -6px;
}
.workshop-schedule.visible .icon-close {
	opacity: 1;
}
.icon-close:hover {
	cursor: pointer;
}

/* popup */

.pop-up {
	position: absolute;
	z-index: 10;
	top: 82px;
	right: 54px;
	width: 300px;
}
.pop-up.one {
	left: 54px;
}
.pop-up.two {
	right: 54px;
}

/* .pop-up.one > div {
	margin: 0;
	padding: 0;
} */

.popup-btn-wrap .popup-btn:first-child {
	border: 0;
	border-right: 0.5px solid #ccc;
}
.popup-btn-wrap .popup-btn {
	width: 50%;
	height: 46px;
	font-size: 14px;
	color: #000;
	border: 0;
	background-color: #f2f2f2;
}

/* 화면 사이즈 별로 캐러셀 이미지 위치 조정 */
/* 가로 1920 이상 기준 */
.jumbotron-carousel >>> .carousel-inner .img-fluid {
	transform: translateY(calc((1920px - 100vw) * 0.25));
}
@media (max-width: 1920px) {
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		transform: none;
	}
}

@media (max-width: 1440px) {
	.jumbotron-carousel >>> .carousel-inner {
		max-height: 620px;
	}
}
@media (max-width: 1200px) {
	h1 {
		font-size: 44px;
	}
}
@media (max-width: 991px) {
	.btn-primary {
		padding: 12px;
		font-size: 18px;
	}
	.desktop-only {
		display: none;
	}
	.main {
		padding-top: 48px;
	}
	h1 {
		font-size: 42px;
	}
	.carousel-slogan {
		font-size: 1.5rem;
		padding-bottom: 5rem;
	}
	.carousel >>> .carousel-indicators li {
		width: 8px;
		height: 8px;
		border-radius: 8px;
		margin: 1.25rem 0.5rem;
	}

	.lead {
		font-size: 1.2rem;
	}
	.jumbotron.splash .lead {
		font-size: 1.3em;
	}
	.jumbotron.wide.halves .card {
		min-width: 40%;
		margin-bottom: 1rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 1rem;
		padding-right: 1rem;
	}
}

@media (max-width: 768px) {
	.btn-primary {
		padding: 12px;
		/* font-size: 18px; */
	}
	.card-wrap .card-body {
		margin-bottom: 30px;
	}
	.container .row {
		padding: 0 10%;
	}
	.container header h2 {
		font-size: 28px;
	}

	.mobile-only {
		display: block !important;
	}
	.logo {
		max-width: 80vw;
	}
	h1 {
		font-size: 36px;
	}
	iframe {
		max-width: 90vw;
		max-height: 50vw;
		width: 560px;
		height: 312px;
	}
	.jumbotron-carousel >>> .carousel-inner {
		min-height: 480px;
	}
	.jumbotron-carousel >>> .carousel-inner .img-fluid {
		height: 480px;
		max-width: 768px !important;
		width: auto !important;
		transform: translateX(calc((100vw - 768px) / 2));
	}
	.carousel-slogan {
		font-size: 1.2rem;
		padding-bottom: 4rem;
	}
	.jumbotron.wide .card-deck {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}
	.jumbotron.wide.thirds .card {
		min-width: 80%;
		margin-bottom: 1rem;
	}
	.card-deck .card {
		margin-right: 10px;
		margin-left: 10px;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 24px;
	}
	.kakao-logo {
		height: 32px;
		margin-left: 8px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 560px;
	}
	.pop-up.one,
	.pop-up.two {
		left: 50%;
		right: unset;
		transform: translateX(-50%);
	}
	.pop-up.two {
		top: 490px;
	}
	.pop-up.login + .pop-up.two {
		top: 704px;
	}
}

@media (max-width: 667px) {
	h1 {
		font-size: 30px;
	}
}
@media (max-width: 576px) {
	.main {
		padding-top: calc(1rem + 30px);
	}
	h1 {
		font-size: 28px;
	}
	.card-deck .card {
		flex: 1 0 0%;
		margin-right: 5px;
		margin-bottom: 0;
		margin-left: 5px;
	}
	.card-deck {
		display: flex;
		flex-flow: row wrap;
		margin-right: 0px;
		margin-left: 0px;
	}
	.card-deck.transparent {
		display: none;
	}
	.card-columns.transparent {
		display: block;
	}
	.card-img {
		padding: 0;
	}
	iframe {
		width: 500px;
		height: 278px;
	}
	.jumbotron.odd,
	.jumbotron.even {
		min-height: 480px;
	}
	.jumbotron.wide .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
	.jumbotron.kakaotalk .lead {
		font-size: 20px;
	}
	.main >>> .tabs .nav-link {
		padding-top: 2vw;
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;
	}
}
@media (max-width: 500px) {
	.jumbotron.wide.halves .card {
		min-width: 80%;
	}
}
@media (max-width: 400px) {
	.main >>> .transparent .card-body {
		padding-top: 0;
		padding-bottom: 0;
	}
}
</style>
